import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { VideocallPipService } from "../videocall-pip.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-webrtc-client",
    templateUrl: "./webrtc-client.component.html",
    styleUrls: ["./webrtc-client.component.scss"],
})
export class WebrtcClientComponent implements OnInit {
    domain = `${window.location.hostname}${Boolean(window.location.port) ? `:${window.location.port}` : ""}`;
    // domain = `dev.goutru.com`;
    localStream: MediaStream;
    @ViewChild("localVideo") localVideo: ElementRef<HTMLVideoElement>;
    @ViewChild("remoteVideo") remoteVideo: ElementRef<HTMLVideoElement>;
    peerConnection: RTCPeerConnection;
    serverConnection: WebSocket;
    dataChannel: RTCDataChannel;
    hasAudio = true;
    hasVideo = true;
    mirrorCamera = false;
    enableToggleCamera = false;

    // Query parameters
    urlParams = new URLSearchParams(window.location.search);
    callId = "123";

    peerConnectionConfig = {
        iceServers: [
            { urls: "stun:stun.stunprotocol.org:3478" },
            { urls: "stun:stun.l.google.com:19302" },
        ],
    };

    @Output() callEnded = new EventEmitter();

    constructor(
        private snackBar: MatSnackBar,
        private videocallService: VideocallPipService,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        this.videocallService.callIdSubscribe({
            next: (callId) => {
                this.callId = callId;
            },
        });

        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        this.init(token);
    }

    ngOnDestroy() {
        if (!this.localStream) {
            return;
        }

        this.localStream.getTracks().forEach((track) => {
            track.stop();
        });
    }

    async init(jwt) {
        // Media access
        try {
            if (!navigator.mediaDevices.getUserMedia) {
                console.log("Your browser does not support getUserMedia API");
                this.handleNoPermissions();
                return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });

            this.localStream = stream;
            this.localVideo.nativeElement.srcObject = stream;
        } catch (error) {
            console.log(error);
            this.handleNoPermissions();
        }

        // WebRTC / WebSocket connection
        try {
            // Start WebRTC (without creating an offer) before connecting to WebSocket
            this.startWebRTC();
            console.log(this.domain);

            // Connect to WebSocket, allowing Kiosk to init WebRTC and create offer which will be answered in handleServerMessage
            this.serverConnection = new WebSocket(
                `wss://${this.domain}/wsrtc/videocall?callId=${this.callId}&client=resident`,
                ["JWT", jwt],
            );
            this.serverConnection.onopen = () =>
                console.log(
                    "Connected to videocall WebSocket as Virtual Guard",
                );
            this.serverConnection.onclose = () =>
                console.log("WebSocket closed");
            this.serverConnection.onmessage =
                this.handleServerMessage.bind(this);
        } catch (error) {
            console.log(error);
        }
    }

    startWebRTC() {
        // Create WebRTC peer connection
        this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig);
        this.peerConnection.onicecandidate = this.gotIceCandidate.bind(this);
        this.peerConnection.ontrack = this.gotRemoteStream.bind(this);

        // Data channel
        this.dataChannel = this.peerConnection.createDataChannel(
            "dataChannel",
            {
                negotiated: true,
                id: 0,
            },
        );
        this.dataChannel.onopen = () => {
            // If camera is disabled notify Kiosk so it can show User placeholder instead of black background
            if (!this.hasVideo) {
                this.dataChannel.send("PEER_DISABLED_CAMERA");
            }
        };
        this.dataChannel.onclose = () => console.log("Data channel closed");
        this.dataChannel.onmessage = this.handleDataChannelMessage.bind(this);

        // WebRTC connection change
        this.peerConnection.onconnectionstatechange = () => {
            switch (this.peerConnection.connectionState) {
                case "connected":
                    console.log("Connected to WebRTC");
                    break;
                case "disconnected":
                case "failed":
                    console.log("Disconnected to WebRTC");
                    this.callEnded.emit();
                    break;
            }
        };

        if (!this.localStream) return;

        for (const track of this.localStream.getTracks()) {
            this.peerConnection.addTrack(track, this.localStream);
        }
    }

    handleServerMessage(message) {
        const data = JSON.parse(message.data);
        // SDP
        if (data.sdp) {
            this.peerConnection
                .setRemoteDescription(new RTCSessionDescription(data.sdp))
                .then(() => {
                    // Only create answers in response to offers
                    if (data.sdp.type === "offer") {
                        // List of available codecs
                        // let codecList = RTCRtpSender.getCapabilities("video").codecs;
                        // console.log('codecs', codecList)
                        // // Transceivers
                        // let transceivers = peerConnection.getTransceivers();
                        // let videoTransceiver = transceivers.find(transceiver => transceiver.sender.track.kind === 'video')
                        // // Set codec preferences
                        // if (videoTransceiver.setCodecPreferences) {
                        //   videoTransceiver.setCodecPreferences([codecList.find(codec => codec.mimeType === "video/AV1")])
                        // }

                        this.peerConnection
                            .createAnswer()
                            .then(this.createdDescription.bind(this))
                            .catch(console.log);
                    }
                })
                .catch(console.log);
        }
        // ICE candidate
        else if (data.ice) {
            this.peerConnection
                .addIceCandidate(new RTCIceCandidate(data.ice))
                .catch(console.log);
        }
        // Signal
        else if (data.signal) {
            const signal = data.signal;
            switch (signal) {
                case "SESSION_ALREADY_EXISTS":
                    this.snackBar.open(
                        this.translate.instant(
                            "VIDEOCALL_PIP.SESSION_ALREADY_EXISTS",
                        ),
                        "OK",
                        {
                            panelClass: ["snack-error"],
                            duration: 5000,
                        },
                    );
                    this.callEnded.emit();
                    break;
                case "KIOSK_SESSION_CLOSED":
                    // Close view when Kiosk ends session
                    this.callEnded.emit();
                    break;
                case "INVALID_CALL":
                    this.snackBar.open(
                        this.translate.instant("VIDEOCALL_PIP.INVALID_CALL"),
                        "OK",
                        { panelClass: ["snack-error"], duration: 5000 },
                    );
                    this.callEnded.emit();
                    break;
                default:
                    break;
            }
        }
    }

    handleDataChannelMessage(message) {
        switch (message.data) {
            // Show toast when Kiosk opened the door (PEER_DOOR_OPENED acknowledge)
            case "KIOSK_DOOR_OPENED":
                this.snackBar.open(
                    this.translate.instant("VIDEOCALL_PIP.DOOR_OPENED"),
                    "OK",
                    {
                        duration: 5000,
                        panelClass: ["snack-success"],
                    },
                );
                break;
            case "ENABLE_ID_CAMERA":
                this.enableToggleCamera = true;
                break;
        }
    }

    gotIceCandidate(event) {
        if (event.candidate != null) {
            this.serverConnection.send(
                JSON.stringify({ ice: event.candidate }),
            );
        }
    }

    createdDescription(description) {
        this.peerConnection
            .setLocalDescription(description)
            .then(() => {
                this.serverConnection.send(
                    JSON.stringify({
                        sdp: this.peerConnection.localDescription,
                    }),
                );
            })
            .catch(console.log);
    }

    gotRemoteStream(event) {
        if (event.track.kind === "video") {
            this.remoteVideo.nativeElement.srcObject = event.streams[0];
        }
    }

    toggleCamera() {
        const videoTrack = this.localStream
            .getTracks()
            .find((track) => track.kind === "video");
        videoTrack.enabled = !videoTrack.enabled;
        this.hasVideo = videoTrack.enabled;

        if (this.dataChannel.readyState !== "open") {
            return;
        }

        this.dataChannel.send(
            videoTrack.enabled ? "PEER_ENABLED_CAMERA" : "PEER_DISABLED_CAMERA",
        );
    }

    toggleMicrophone() {
        const audioTrack = this.localStream
            .getTracks()
            .find((track) => track.kind === "audio");
        audioTrack.enabled = !audioTrack.enabled;
        this.hasAudio = audioTrack.enabled;
    }

    openDoor() {
        if (this.dataChannel.readyState !== "open") {
            return;
        }

        this.dataChannel.send("PEER_DOOR_OPENED");
    }

    toggleCameraKiosk() {

        console.log("TOGGLE_CAMERA_KIOSK");

        const video = document.getElementById('remoteVideo') as HTMLVideoElement;

        const widthCanvasPhoto = video.videoWidth;
        const heightCanvasPhoto = video.videoHeight;

        const canvasElement = document.getElementById('canvas') as HTMLCanvasElement;
        const context = canvasElement.getContext('2d');
        const photo = document.getElementById('photo') as HTMLImageElement;

        canvasElement.width = widthCanvasPhoto;
        canvasElement.height = heightCanvasPhoto;

        photo.width = widthCanvasPhoto;
        photo.height = heightCanvasPhoto;

        // Logica para voltear horizontalmente
        if (this.mirrorCamera) {
            context.scale(-1, 1);
            context.drawImage(video, -canvasElement.width, 0, canvasElement.width, canvasElement.height);
            this.mirrorCamera = false;
            video.style.transform = "scaleX(1)";
        } else {
            this.mirrorCamera = true;
            context.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            video.style.transform = "scaleX(-1)";
        }

        const dataURL = canvasElement.toDataURL('image/png');
        photo.setAttribute('src', dataURL);
        photo.style.display = "block"
        this.dataChannel.send("TOGGLE_CAMERA_KIOSK");
    }

    hangUp() {
        // Close WebRTC session
        this.peerConnection.close();
        // Close WebSocket session
        this.serverConnection.close();

        // Stop capturing media devices
        this.localStream.getTracks().forEach((track) => {
            track.stop();
        });

        // Notify videocall pip to close itself
        this.callEnded.emit();
    }

    handleSingleClickOpenDoor() {
        this.snackBar.open(
            this.translate.instant("VIDEOCALL_PIP.OPEN_DOOR_DOUBLE_CLICK"),
            "OK",
            {
                duration: 5000,
            },
        );
    }

    handleNoPermissions() {
        this.snackBar.open(
            this.translate.instant("VIDEOCALL_PIP.NO_PERMISSIONS"),
            "OK",
            {
                panelClass: ["snack-error"],
                duration: 5000,
            },
        );
        this.callEnded.emit();
    }
}
