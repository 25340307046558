import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService } from 'app/main/auth/auth.service';
import { SecurityService } from 'app/main/security/security.service';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { VideocallPipService } from './videocall-pip.service';

const notificationTypes = {
  start: 'start_videocall',
  cancel: 'cancel_videocall',
  answered: 'answered_videocall',
};

@Component({
  selector: 'app-videocall-pip',
  templateUrl: './videocall-pip.component.html',
  styleUrls: ['./videocall-pip.component.scss'],
  animations: [
    trigger('appear', [
      state('void', style({ opacity: 0, transform: 'scale(0.8)' })),
      transition(':enter', [
        animate('100ms ease', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
    trigger('visibilityChanged', [
      state('hidden', style({ opacity: 0, visibility: 'hidden', transform: 'scale(0.8)' })),
      state('visible', style({ opacity: 1, visibility: 'visible', transform: 'scale(1)' })),
      transition('visible => hidden', animate('100ms ease')),
    ]),
  ],
})
export class VideocallPipComponent implements OnInit {
  gateId: string | null = null;
  incomingCall = false;
  acceptedCall = false;
  wsClient: any;
  sessionData = {
    apiKey: '',
    sessionId: '',
    token: '',
    kiosk: '',
    callId: '',
  };
  hasAudio = true;
  hasVideo = true;
  @ViewChild(CdkDrag) videoCallContainer: CdkDrag;
  audio = new Audio('assets/sounds/ringtone-tizzy.wav');

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private securityService: SecurityService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private videocallService: VideocallPipService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
  }

  ngOnInit() {
    // Subscribe to the gate ID changes
    this.securityService.subscribe((data: { value: string }) => {
      if (!data) {
        return;
      }

      this.gateId = data.value;

      // Start WebSocket when the gate ID is set
      this.setupVideocallWebSocket(data.value);
    });

    // Listen for logout event
    this.authService.subscribe(() => {
      this.handleLogout();
    });
  }

  handleLogout() {
    this.gateId = null;
    this.incomingCall = false;
    if (this.wsClient) {
      this.wsClient.disconnect();
    }

    this.videocallService.setCallId(null);
    this.endCall();
  }

  /**
   * Sets up a WebSocket connection for a video call with the specified gate ID.
   * @param gateId The ID of the gate to connect to.
   */
  setupVideocallWebSocket(gateId: string) {
    if (this.wsClient) {
      this.wsClient.disconnect();
    }

    // const value = localStorage.getItem("videocall") ;

    // if (value !=="0"  && value !==null ) {
    //     return;                
    // }

    this.wsClient = this.securityService.setupVideocallWebSocket();

    // localStorage.setItem("videocall","1");


    this.wsClient.subscribe(`/virtualGuardVideocall/${gateId}`, (data) => {
      if (
        this.acceptedCall ||
        !this.securityService.isCheckedIn ||
        !this.securityService.selectedBuildingSettings.showVirtualGuard
      ) {
        return;
      }

      if (data.type === notificationTypes.cancel || data.type === notificationTypes.answered) {
        this.hideCall();
        return;
      }

      // Start a new video call if there is no active session
      this.incomingCall = true;
      this.playCallSound();

      this.sessionData = {
        apiKey: data.apiKey,
        sessionId: data.sessionId,
        token: data.guardUserToken,
        kiosk: data.kiosk,
        callId: data.callId,
      };
    });
  }

  acceptCall() {
    this.incomingCall = false;
    this.acceptedCall = true;

    this.stopCallSound();

    this.securityService.answerVideoCall(this.gateId).subscribe({
      next: () => { }
    });

    this.videocallService.setCallId(this.sessionData.callId);
  }

  /**
   * Rejects an incoming call and resets the video call position and sound.
   */
  hideCall() {
    this.incomingCall = false;
    this.acceptedCall = false;

    this.resetVideocallPosition();
    this.stopCallSound();
  }


  handleError(error: any) {
    if (error) {
      this.snackBar.open(error.message, 'OK', {
        duration: 5000,
        panelClass: ["snack-error"],
      });
    }
  }

  /**
   * Ends the ongoing video call session and cleans up resources.
   */
  endCall() {
    this.acceptedCall = false;

    this.hasAudio = true;
    this.hasVideo = true;
    this.resetVideocallPosition();
    this.stopCallSound();
  }

  /**
   * Resets the position of the video call container after a delay of 250ms.
   */
  resetVideocallPosition() {
    setTimeout(() => {
      if (this.videoCallContainer) {
        this.videoCallContainer.reset();
      }
    }, 250);
  }

  playCallSound() {
    this.audio.loop = true;
    this.audio.play();
  }

  stopCallSound() {
    this.audio.pause();
  }

  handleEndCall() {
    this.videocallService.setCallId(null);
    this.endCall();
  }

  handleSingleClickOpenDoor() {
    this.snackBar.open('You must double click to open the door', 'OK', {
      duration: 5000,
    });
  }
}